
import {
  Branding,
  BrandingEntity,
  ConfigValueName,
  LanguageKey,
} from '@govflanders/mbp-admin-panel-shared';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import MultiLanguageImageUpload from '@/components/shared/MultiLanguageImageUpload.vue';
import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';

@Component({
  components: {
    MultiLanguageInput,
    MultiLanguageImageUpload,
  },
})
export default class BrandingEntitylogoBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: BrandingEntity;

  @Prop({
    type: Object,
    required: true,
  })
  public base: Branding;

  public readonly TEXT_HOST_TYPE = 'text';
  public readonly IMAGE_HOST_TYPE = 'image';

  @Getter('config/get')
  private getConfig: (value: ConfigValueName) => string;

  public get widgetsDirectory(): string {
    return this.getConfig(ConfigValueName.CLOUDINARY_FILE_PREFIX);
  }

  public get currentValue(): BrandingEntity {
    return this.value;
  }

  public set currentValue(value: BrandingEntity) {
    this.$emit('input', value);
  }

  public addHost(type) {
    this.currentValue = { type };
    this.updateAllLanguages({ type });
  }

  public get activeTab(): number {
    if (
      !this.currentValue ||
      !this.currentValue.type ||
      this.currentValue.type === this.TEXT_HOST_TYPE
    ) {
      return 0;
    }
    return 1;
  }

  private updateAllLanguages(value) {
    if (!this.base.i18n) {
      return;
    }

    const languages = (Object.keys(this.base.i18n.translations) || []) as LanguageKey[];
    languages.forEach((language) => {
      this.$set(this.base.i18n.translations[language], 'logos', {
        entity: value ? { ...value } : null,
      });
    });
  }

  public handleRemoveEntityLogo() {
    this.currentValue = null;
    this.updateAllLanguages(null);
  }
}
