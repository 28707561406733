
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Branding, BrandingUmbrella, ConfigValueName } from '@govflanders/mbp-admin-panel-shared';

import ImageUpload from '@/components/shared/forms/ImageUpload.vue';
import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';
import MultiLanguageImageUpload from '@/components/shared/MultiLanguageImageUpload.vue';

@Component({
  components: {
    ImageUpload,
    MultiLanguageInput,
    MultiLanguageImageUpload,
  },
})
export default class BrandingUmbrellaBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: BrandingUmbrella;

  @Prop({
    type: Object,
    required: true,
  })
  public base: Branding;

  public get currentValue(): BrandingUmbrella {
    return this.value;
  }

  public set currentValue(value: BrandingUmbrella) {
    this.$emit('input', value);
  }

  @Getter('config/get')
  private getConfig: (value: ConfigValueName) => string;

  public get widgetsDirectory(): string {
    return this.getConfig(ConfigValueName.CLOUDINARY_FILE_PREFIX);
  }
}
