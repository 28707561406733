
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Branding, BrandingHost } from '@govflanders/mbp-admin-panel-shared';

import MultiLanguageInput from '@/components/shared/MultiLanguageInput.vue';


@Component({
  components: {
    MultiLanguageInput,
  },
})
export default class BrandingHostBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: BrandingHost;

  @Prop({
    type: Object,
    required: true,
  })
  public base: Branding;

  public get currentValue(): BrandingHost {
    return this.value;
  }

  public set currentValue(value: BrandingHost) {
    this.$emit('input', value);
  }
}
