import { Schema, Templates } from '@/json';
import { Branding } from '@govflanders/mbp-admin-panel-shared';

export function getBrandingSchema(): object {
  return Schema.Branding;
}

export function getBrandingTemplate(): Branding {
  return Object.assign({}, Templates.Branding as Branding);
}

export * from './contrast-check';
