import contrastChecker from 'color-contrast-checker';
import i18n from '../../i18n';

const WHITE = '#FFF';
const GREY = '#333332';
const ACCEPTABLE_CONTRAST = 4.5;

const hasValidColorContrast = (foregroundColor: string, backgroundColor: string) => {
  const checker = new contrastChecker();
  try {
    return checker.isLevelCustom(foregroundColor, backgroundColor, ACCEPTABLE_CONTRAST);
  } catch (err) {
    return false;
  }
};

export const isPrimaryColorContrastOk = (color: string) => {
  const isWhiteContrastOk = hasValidColorContrast(color, WHITE);
  const isGreyContrastOk = hasValidColorContrast(color, GREY);

  return isWhiteContrastOk || isGreyContrastOk;
};

export const isFunctionalColorContrastOk = (color: string) => {
  const isWhiteContrastOk = hasValidColorContrast(color, WHITE);

  return isWhiteContrastOk;
};

export const getContrastMessages = (primary: string, functional: string) => {
  const messages = [];

  const primaryColorContrastOk = isPrimaryColorContrastOk(primary);
  const functionalColorContrastOk = isFunctionalColorContrastOk(functional);

  if (primary && !primaryColorContrastOk)
    messages.push(
      `Primary color ${primary} is niet ok. ${i18n.t('widgets.configform.primaryColorInfo')}
        `,
    );
  if (functional && !functionalColorContrastOk)
    messages.push(
      `Functional color ${functional} is niet ok. ${i18n.t(
        'widgets.configform.functionalColorInfo',
      )}`,
    );

  return messages;
};
