
import { Component, Prop, Vue } from 'vue-property-decorator';
import LogoUploadModal from '@/components/shared/LogoUploadModal.vue';
import { Inject } from 'inversify-props';
import { LOGO_SERVICE, LogoService } from '@/services';
import { LanguageKey, Widget, WidgetExtension } from '@govflanders/mbp-admin-panel-shared';

@Component({
  components: { LogoUploadModal },
})
export default class MultiLanguageImageUpload extends Vue {
  @Prop({
    type: Object,
  })
  public base: Widget | WidgetExtension; // base value where the property is stored on

  public umbrellaSources = [];

  @Prop({ default: () => Date.now().toString() })
  public readonly modalId: string;

  @Prop({ type: Boolean })
  public readonly modNoPreview: boolean;

  @Prop({ type: Boolean })
  public readonly modIconPreview: boolean;

  @Prop()
  public label: string;

  @Prop()
  public directory: string;

  @Prop({
    type: Array,
    required: true,
  })
  public valuePath: string[]; // path to the property on the base value

  @Inject(LOGO_SERVICE)
  public logoService: LogoService;

  mounted() {
    this.fetchFilenames();
  }

  get languages() {
    const i18n = this.base.i18n;
    return Object.keys(i18n.translations) as LanguageKey[];
  }

  public getModalId(language: string) {
    return `${this.modalId}-${language}`;
  }

  public fetchFilenames(): void {
    this.logoService
      .fetchFilenames(this.directory)
      .then((data) => {
        this.umbrellaSources = data;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  public useForAllLanguages(language: LanguageKey) {
    const value = this.getValue(language);
    this.languages.forEach((lang) => {
      this.setValue(value, lang);
    });
  }

  public showUseForAllButton() {
    const uniqueValues = new Set(this.languages.map((lang) => this.getValue(lang)));
    return uniqueValues.size > 1;
  }

  setValue(value: string, language: LanguageKey) {
    const setValue = (base: object | object[], path: string[], value: string) => {
      if (path.length === 1) {
        this.$set(base, path[0], value);
        return;
      }
      if (!base[path[0]]) {
        this.$set(base, path[0], this.isArrayIndex(path[1]) ? [] : {});
      }
      setValue(base[path[0]], path.slice(1), value);
    };
    if (language === 'nl') {
      setValue(this.base, this.valuePath, value);
    }

    setValue(this.base.i18n.translations[language], this.valuePath, value);
  }

  getValue(language: LanguageKey) {
    const getValue = (base: object | object[], path: string[]) => {
      if (path.length === 1) {
        if (!base[path[0]]) {
          this.$set(base, path[0], '');
        }
        return base[path[0]];
      }
      if (!base[path[0]]) {
        this.$set(base, path[0], this.isArrayIndex(path[1]) ? [] : {});
      }
      return getValue(base[path[0]], path.slice(1));
    };

    return getValue(this.base.i18n.translations[language], this.valuePath);
  }

  public fileUploaded(value: string, language: LanguageKey): void {
    this.fetchFilenames();
    this.setValue(value, language);
  }

  isArrayIndex(path) {
    return (path || path === 0) && Number.isInteger(parseInt(path));
  }
}
